/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import useWidth from "../../../hooks/useWidth";
import SimpleFooter from "../../../examples/Footers/SimpleFooter";
import React from "react";
import bgImage from "../../../assets/images/vr-bg.jpg";

function Privacy() {
  const width = useWidth();

  return (
    <>
      <MKBox
        component="div"
        pt={20}
        pb={12}
        // maxWidth={1000}
        sx={{
          m: "auto",
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        bg
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  px={width !== "xs" && width !== "md" ? 3 : 2}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h4" color="light">
                    Конфиденциальность и политика
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    color="white"
                    opacity={0.8}
                    sx={{ fontWeight: 500 }}
                  >
                    Последнее изменение: 19 августа 2024 г.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={width !== "xs" && width !== "md" ? 6 : 2.5}>
                  <MKTypography variant="h5" mt={6} mb={3} color="dark">
                    Введение
                  </MKTypography>
                  <MKTypography variant="body2" color="dark" sx={{ fontWeight: 500 }}>
                    В компании{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://zema.ai"
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        fontWeight: 500,
                        "&:hover": { color: ({ palette: { info } }) => info.main },
                      }}
                    >
                      ZEMA{" "}
                    </MKTypography>
                    одним из наших главных приоритетов является конфиденциальность наших
                    посетителей. В данном документе о политике конфиденциальности указаны типы
                    информации, которую собирает и записывает «zema.ai», и то, как мы ее используем.
                    <br />
                    <br />
                    Если у вас возникнут дополнительные вопросы или вам потребуется дополнительная
                    информация о нашей политике конфиденциальности, не стесняйтесь обращаться к нам
                    по электронной почте info@zema.ai.
                    <br />
                    <br />
                    Данная политика конфиденциальности распространяется только на нашу деятельность
                    в интернете. Эта политика не применима к любой информации, собранной в
                    автономном режиме или через другие каналы, кроме этого сервиса{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://zema.ai"
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        fontWeight: 500,
                        "&:hover": { color: ({ palette: { info } }) => info.main },
                      }}
                    >
                      zema.ai
                    </MKTypography>
                    .
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3} color="dark">
                    Итак:
                  </MKTypography>
                  <MKBox
                    component="ul"
                    my={3}
                    ml={width !== "xs" && width !== "md" ? 6 : 2}
                    color="dark"
                    sx={{ fontWeight: 500 }}
                  >
                    <MKBox component="li">
                      <MKTypography
                        variant="body2"
                        fontWeight="regular"
                        color="dark"
                        sx={{ fontWeight: 500 }}
                      >
                        zema.ai уважает своего пользователя и его право на защиту персональных
                        данных. Данный документ определяет политику сервиса в отношении обработки
                        персональных данных и реализации требований к защите персональных данных.
                        Пользователь дает свое согласие сервису zema.ai на обработку персональных
                        данных пользователя, предоставленных при регистрации, а именно: - адрес
                        электронной почты;
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography
                        variant="body2"
                        color="dark"
                        sx={{ fontWeight: 500 }}
                        fontWeight="regular"
                      >
                        Обработка персональных данных означает: <br />
                        Типы данных: <br />
                        - Адрес электронной почты. <br />
                        - История запросов поиска. <br />
                        Цель сбора данных: <br />
                        - Электронная почта используется для авторизации и контроля лимита на
                        количество задаваемых вопросов. <br />
                        - История запросов поиска хранится для улучшения пользовательского опыта,
                        предоставления релевантных рекомендаций, и аналитики использования сервиса.
                        <br />
                        Обработка и хранение данных: <br />
                        - Адреса электронной почты и история запросов хранятся в безопасной среде.
                        <br />- Данные используются только для целей авторизации, управления
                        лимитами и улучшения сервиса. <br /> Данные не передаются третьим лицам и
                        используются только в рамках предоставляемого функционала.
                      </MKTypography>
                    </MKBox>
                    {/*<MKBox component="li">*/}
                    {/*  <MKTypography*/}
                    {/*    variant="body2"*/}
                    {/*    color="dark"*/}
                    {/*    sx={{ fontWeight: 500 }}*/}
                    {/*    fontWeight="regular"*/}
                    {/*  >*/}
                    {/*    Кроме того, обработка персональных данных пользователя производится сервисом*/}
                    {/*    с использованием баз данных на территории Европейского Союза.*/}
                    {/*  </MKTypography>*/}
                    {/*</MKBox>*/}

                    <MKBox component="li">
                      <MKTypography
                        variant="body2"
                        color="dark"
                        sx={{ fontWeight: 500 }}
                        fontWeight="regular"
                      >
                        Пользователь может в любое время отозвать согласие на обработку персональных
                        данных, направив в поддержку соответствующее уведомление на электронный
                        адрес info@zema.ai.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography
                        variant="body2"
                        color="dark"
                        sx={{ fontWeight: 500 }}
                        fontWeight="regular"
                      >
                        Пользователь дает согласие на получение информационных рассылок от сервиса
                        zema, на адрес электронной почты, указанный пользователем при регистрации.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography
                        variant="body2"
                        color="dark"
                        sx={{ fontWeight: 500 }}
                        fontWeight="regular"
                      >
                        Согласие на получение информационных рассылок может быть отозвано
                        пользователем в любое время путем направления администратору
                        соответствующего уведомления электронный адрес info@zema.ai. После получения
                        такого уведомления сервис zema прекращает направлять на указанный
                        пользователем при регистрации адрес электронной почты сообщения с
                        информационной рассылкой.
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {width !== "xs" && width !== "md" ? (
        <MKBox pt={6} px={1} mt={6} mb={3}>
          <SimpleFooter />
        </MKBox>
      ) : (
        <MKBox
          sx={{
            // position: "absolute",
            // bottom: 0,
            width: "100%",
          }}
        >
          <MKBox mt={1}>
            <SimpleFooter />
          </MKBox>
        </MKBox>
      )}
    </>
  );
}

export default Privacy;
