/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import reciter1 from "assets/images/reciters/Abdullaah3awwaadAl.jpeg";
import reciter2 from "assets/images/reciters/AhmedIbnAliAlAjamy.jpeg";
import reciter3 from "assets/images/reciters/AhmedNeana.jpeg";
import reciter4 from "assets/images/reciters/AliJabir.jpeg";
import reciter5 from "assets/images/reciters/Basit.jpeg";
import reciter6 from "assets/images/reciters/AkramAlAlaqimy.jpeg";
import reciter7 from "assets/images/reciters/AymanSowaid.jpeg";
import reciter8 from "assets/images/reciters/AzizAlili.jpeg";
import reciter9 from "assets/images/reciters/Basfar.jpeg";

import { reciters } from "config";

function Messages(props) {
  const { reciter, setReciter } = props;

  const messagesAvatarStyles = {
    border: ({ borders: { borderWidth }, palette: { white } }) =>
      `${borderWidth[2]} solid ${white.main}`,
    cursor: "pointer",
    position: "relative",
    ml: -1.5,

    "&:hover, &:focus": {
      zIndex: "10",
    },
  };

  return (
    <Card>
      <MKBox display="flex" alignItems="center" justifyContent="space-between" p={3}>
        <MKTypography variant="body2" color="text">
          Чтецы
        </MKTypography>
        <MKBox display="flex">
          {reciters.map((item) => {
            const { key, value, shortName, url } = item;
            return (
              <Tooltip key={key} title={value} placement="top">
                <MKAvatar
                  onClick={() => {
                    setReciter(key);
                  }}
                  src={url}
                  alt={key}
                  size="sm"
                  sx={messagesAvatarStyles}
                />
              </Tooltip>
            );
          })}
        </MKBox>
      </MKBox>
    </Card>
  );
}

export default Messages;
