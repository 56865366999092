/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Outlet, RouterProvider, createBrowserRouter, redirect } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

import { fakeAuthProvider } from "auth";
import SignInCoverPage from "layouts/authentication/sign-in/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";
import ConfirmCoverPage from "layouts/authentication/confirm/cover";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import VirtualRealityPage from "layouts/pages/extra/virtual-reality";
import PrivacyPage from "layouts/pages/support/privacy";

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function protectedLoader({ request }) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!fakeAuthProvider.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

async function loginAction({ request }) {
  let formData = await request.formData();
  let username = formData.get("username");
  let password = formData.get("password");

  // Validate our form inputs and return validation errors via useActionData()
  if (!username) {
    return {
      error: "Похоже забыли логин",
    };
  }
  if (!password) {
    return {
      error: "Похоже забыли пароль",
    };
  }

  // Sign in and redirect to the proper destination if successful.
  try {
    await fakeAuthProvider.signin({ username: username.toLowerCase(), password });
  } catch (error) {
    console.log(error.message);
    // Unused as of now but this is how you would handle invalid
    // username/password combinations - just like validating the inputs
    // above
    return {
      error: error.message,
    };
  }

  let redirectTo = formData.get("redirectTo");
  return redirect(redirectTo || "/");
}

async function loginLoader() {
  if (fakeAuthProvider.isAuthenticated) {
    return redirect("/");
  }
  return null;
}

async function resetAction({ request }) {
  let formData = await request.formData();
  let email = formData.get("email");
  let email2 = formData.get("email2");
  let password = formData.get("password");

  console.log(email, "email");
  console.log(email2, "email2");

  // Validate our form inputs and return validation errors via useActionData()
  if (!email || !email2) {
    return {
      error: "Похоже забыли почту",
    };
  }

  if (email && email.length && email2 && email2.length && email.trim() !== email2.trim()) {
    return {
      error: "Почты должны совпадать",
    };
  }

  // Sign in and redirect to the proper destination if successful.
  try {
    const result = await fakeAuthProvider.reset({
      email: email.toLowerCase(),
      password: email.toLowerCase(),
    });
    return {
      success: result.status,
    };
  } catch (error) {
    console.log(error.message);
    // Unused as of now but this is how you would handle invalid
    // username/password combinations - just like validating the inputs
    // above
    return {
      error: error.message,
    };
  }

  let redirectTo = formData.get("redirectTo");
  return redirect(redirectTo || "/login");
}

async function resetLoader() {
  if (fakeAuthProvider.isAuthenticated) {
    return redirect("/login");
  }
  return null;
}

async function signUpAction({ request }) {
  let formData = await request.formData();
  let email = formData.get("email");
  let email2 = formData.get("email2");
  let username = formData.get("username");
  let password = formData.get("password");

  // Validate our form inputs and return validation errors via useActionData()
  if (!email || !email2) {
    return {
      error: "Похоже забыли почту",
    };
  }
  if (email && email.length && email2 && email2.length && email.trim() !== email2.trim()) {
    return {
      error: "Почты должны совпадать",
    };
  }
  // if (!username) {
  //   return {
  //     error: "Похоже забыли логин",
  //   };
  // }
  // if (!password) {
  //   return {
  //     error: "Похоже забыли пароль",
  //   };
  // }

  // Sign in and redirect to the proper destination if successful.
  try {
    const result = await fakeAuthProvider.signup({
      email: email.toLowerCase(),
      username: email.toLowerCase(),
      password: email,
    });
    return {
      success: result.status,
    };
  } catch (error) {
    console.log(error.message);
    // Unused as of now but this is how you would handle invalid
    // username/password combinations - just like validating the inputs
    // above
    return {
      error: error.message,
    };
  }

  let redirectTo = formData.get("redirectTo");
  return redirect(redirectTo || "/sign-up");
}

async function signUpLoader() {
  if (fakeAuthProvider.isAuthenticated) {
    return redirect("/sign-up");
  }
  return null;
}

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader() {
      // Our root route always provides the user, if logged in
      return { user: fakeAuthProvider.username };
    },
    Component: Layout,
    children: [
      {
        index: true,
        Component: VirtualRealityPage,
        // loader: protectedLoader,
        path: "",
      },
      {
        index: true,
        Component: PrivacyPage,
        // loader: protectedLoader,
        path: "privacy",
      },
      {
        path: "login",
        action: loginAction,
        loader: loginLoader,
        Component: SignInCoverPage,
      },
      {
        path: "reset",
        action: resetAction,
        loader: resetLoader,
        Component: ResetPasswordPage,
      },
      {
        path: "sign-up",
        action: signUpAction,
        loader: signUpLoader,
        Component: SignUpCoverPage,
      },
      {
        path: "confirm/:id",
        loader: () => null,
        Component: ConfirmCoverPage,
      },
    ],
  },
  {
    path: "/logout",
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      await fakeAuthProvider.signout();
      return redirect("/login");
    },
  },
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    </ThemeProvider>
  );
}
