/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// router
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKSnackbar from "components/MKSnackbar";

// VirtualReality page components
import MediaPlayer from "pages/Extra/VirtualReality/components/MediaPlayer";
import Messages from "pages/Extra/VirtualReality/components/Messages";
import NotificationModal from "layouts/sections/attention-catchers/modals/components/NotificationModal";
import NotificationModal2 from "layouts/sections/attention-catchers/modals/components/NotificationModal2";

import Typed from "typed.js";

// Images
import logo from "assets/images/small-logos/logo.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SimpleFooter from "examples/Footers/SimpleFooter";

import ScrollTop from "pages/Extra/VirtualReality/components/ScrollTop";
import { POST_ME } from "../../../config";
import useAsync from "../../../hooks/useAsync";
import { blue } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import useWidth from "../../../hooks/useWidth";
import Rellax from "rellax";

import Extra2 from "pages/Extra2/VirtualReality";
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import routes from "../../../routes";
import bgImage from "../../../assets/images/vr-bg.jpg";
// import bgImage from "assets/images/bg-rental.jpeg";
import MKAvatar from "../../../components/MKAvatar";
import team1 from "../../../assets/images/team-1.jpg";
import sunCloud from "../../../assets/images/small-logos/icon-sun-cloud.png";
import TodoList from "./components/TodoList";
import TodoCard from "./components/TodoCard";
import Emails from "./components/Emails";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import footerRoutes from "../../../footer.routes";

function VirtualReality() {
  const navigate = useNavigate();
  const [reciter, setReciter] = useState("Alhashim");

  const width = useWidth();
  const { t } = useTranslation();

  // Setting up typedJS

  const [typeOfSnackbar, setTypeOfSnackbar] = useState("dark");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const fetchData = useCallback(async () => {
    const fetchUrl = POST_ME();
    const accessToken = localStorage.getItem("access_token");

    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) {
      const body = await response.json();
      if (response.status === 429) {
        setErrorMessage(body.error);
      } else if (response.status === 409 || response.status === 404) {
        setErrorMessage(body.detail);
      } else if (response.status === 422) {
        setErrorMessage(
          <div>
            Необходимо{" "}
            <Link to="/login" style={{ textDecoration: "none" }}>
              авторизоваться
            </Link>
          </div>
        );
      } else {
        setErrorMessage(error.message);
      }
      setTypeOfSnackbar("error");
      setShow(true);
    }

    const data = await response.json();
    if (data === null) {
      setErrorMessage("Ничего не нашлось (");
      setTypeOfSnackbar("info");
      setShow(true);
    }

    return data;
  }, []);

  const { execute, status, value, error } = useAsync(fetchData, false);

  useEffect(() => {
    console.log(error, "ERROR");
    if (
      (error && error.message === "Failed to fetch") ||
      (error && error.message === "The string did not match the expected pattern.")
    ) {
      setErrorMessage("Упсссс... Что-то пошло не так (");
      setTypeOfSnackbar("error");
      setShow(true);
    }
    if (status === "success" && value) {
      setShowModal(!showModal);
    }
  }, [status, value, error]);

  return (
    <>
      <MKBox
        component="header"
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          // backgroundColor: "rgb(206, 212, 218)",
        }}
      >
        <Container>
          <Grid container spacing={3} justifyContent="center" pt={{ xs: 16, lg: 6 }}>
            <Grid item xs={12} md={1}>
              <MKBox
                display="flex"
                flexDirection={{ xs: "row", md: "column" }}
                justifyContent="center"
                alignItems="center"
                px={2}
                mb={{ xs: 8, md: 0 }}
              >
                <MKBox my={{ xs: 0, md: 2 }} mx={{ xs: 2, md: 0 }}>
                  <Tooltip title="Важная информация" placement="right">
                    <MKButton
                      onClick={() => setShowModal2(true)}
                      iconOnly
                      size={width === "xs" || width === "md" ? "small" : "large"}
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Icon color="inherit">error_outline</Icon>
                    </MKButton>
                  </Tooltip>
                </MKBox>
                <MKBox mb={{ xs: 0, md: 2 }} mr={{ xs: 2, md: 0 }}>
                  <Tooltip title="Войти" placement="right">
                    <Link to="/login">
                      <MKButton
                        iconOnly
                        size={width === "xs" || width === "md" ? "small" : "large"}
                        sx={({ palette: { black }, borders: { borderRadius } }) => ({
                          color: black.main,
                          borderRadius: borderRadius.lg,
                        })}
                      >
                        <Icon color="inherit">login</Icon>
                      </MKButton>
                    </Link>
                  </Tooltip>
                </MKBox>
                <MKBox mb={{ xs: 0, md: 2 }} mr={{ xs: 2, md: 0 }}>
                  <Tooltip title="Отправить фидбэк" placement="right">
                    <MKButton
                      disabled={status === "pending"}
                      onClick={() => {
                        // setShowModal(!showModal);
                        execute();
                        // localStorage.getItem("access_token");
                        // window.location.reload();
                        // navigate(`/login`, { replace: false });
                      }}
                      iconOnly
                      size={width === "xs" || width === "md" ? "small" : "large"}
                      sx={({ palette: { black }, borders: { borderRadius } }) => ({
                        color: black.main,
                        borderRadius: borderRadius.lg,
                        position: "relative",
                      })}
                    >
                      <Icon color="inherit">email</Icon>
                      {status === "pending" ? (
                        <CircularProgress
                          size={width === "xs" || width === "md" ? 26 : 50}
                          sx={{
                            color: blue[500],
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        />
                      ) : null}
                    </MKButton>
                  </Tooltip>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={11} xl={8}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "center", md: "flex-start" }}
                ml={{ xs: 1, md: 4 }}
                mt={-1}
              >
                <MKBox>
                  <MKTypography
                    variant="h1"
                    sx={{
                      fontSize: ({ typography: { d1, h2 } }) => ({
                        xs: h2.fontSize,
                        lg: d1.fontSize,
                      }),
                    }}
                    color="white"
                    lineHeight={1}
                  >
                    zema.ai
                  </MKTypography>
                  <MKTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                    textTransform="uppercase"
                  >
                    быстрый поиск по исламу
                  </MKTypography>
                </MKBox>

                <MKBox
                  style={
                    width === "xs" || width === "md"
                      ? {}
                      : {
                          marginTop: -50,
                        }
                  }
                  component="img"
                  src={logo}
                  width="30%"
                />
              </MKBox>
              <MKBox mt={3} mb={8} ml={{ xs: 1, md: 4 }} mr={{ xs: 1, md: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TodoList />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MKBox mb={3}>
                      <TodoCard />
                    </MKBox>
                    <Emails />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MKBox mb={3}>
                      <MediaPlayer
                        show={show}
                        setShow={setShow}
                        setTypeOfSnackbar={setTypeOfSnackbar}
                        reciter={reciter}
                        setReciter={setReciter}
                        setErrorMessage={setErrorMessage}
                      />
                    </MKBox>
                    <Messages reciter={reciter} setReciter={setReciter} />
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          // minHeight: width === "xs" || width === "md" ? "calc(100vh - 75px)" : "auto",
        }}
      >
        <MKBox>
          <NotificationModal
            show={showModal}
            setShow={setShowModal}
            setShowSnackber={setShow}
            setErrorMessage={setErrorMessage}
            setTypeOfSnackbar={setTypeOfSnackbar}
          />

          <NotificationModal2 show={showModal2} setShow={setShowModal2} />
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color={typeOfSnackbar}
            icon="notifications"
            title={
              typeOfSnackbar === "error"
                ? "Ошибка 👀"
                : typeOfSnackbar === "success"
                ? "Успешно"
                : ""
            }
            content={errorMessage}
            dateTime=""
            open={show}
            close={() => setShow(false)}
          />
        </MKBox>
        {/*<ScrollTop>*/}
        {/*  <Fab size="small" aria-label="scroll back to top">*/}
        {/*    <KeyboardArrowUpIcon />*/}
        {/*  </Fab>*/}
        {/*</ScrollTop>*/}
        <MKBox pt={6} px={1} mt={6} mb={3}>
          <SimpleFooter />
        </MKBox>
      </div>
    </>
  );
}

export default VirtualReality;
