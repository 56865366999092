/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import volunter from "../../../../../assets/volunteer_activism.svg";
import MuiLink from "@mui/material/Link";
import React from "react";

function TodoCard() {
  return (
    <MKBox bgColor="dark" variant="gradient" borderRadius="xl" shadow="lg">
      <MKBox p={3}>
        <Grid container wrap="nowrap" spacing={2} alignItems="center">
          <Grid item>
            <MKBox component="img" src={volunter} width="50px" height="50px" />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <MuiLink
              href="https://mamedblog.com/donat"
              target="_blank"
              rel="noreferrer"
              variant="button"
            >
              <MKTypography
                sx={{
                  fontSize: "0.85rem",
                  fontWeight: "bold",
                  textDecoration: "underline !important",
                  // lineHeight: "0px !important",
                }}
              >
                Поддержать проект
              </MKTypography>
            </MuiLink>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default TodoCard;
