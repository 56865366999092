/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React pages
import Rental from "pages/LandingPages/Rental";

export default function RentalPage() {
  return <Rental />;
}
