/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// routes
import footerRoutes from "footer.routes";

function FooterTwo() {
  return <DefaultFooter content={footerRoutes} />;
}

export default FooterTwo;
