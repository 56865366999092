const API_HOST_PROD = "/api/v1";
const API_HOST_STAGE = "/api/v1";
const API_HOST_LOCAL = "http://localhost:8000/api/v1";

let API_HOST = process.env.NODE_ENV === "development" ? API_HOST_STAGE : API_HOST_PROD;

if (window.location.hostname === "localhost") {
  API_HOST = API_HOST_LOCAL;
}

export const POST_LOGIN = () => `${API_HOST}/auth/login`;
export const POST_SIGNUP = () => `${API_HOST}/auth/signup`;
export const POST_RESET = () => `${API_HOST}/auth/reset`;
export const POST_CONFIRM = (id) => `${API_HOST}/auth/confirm/${id}`;
export const POST_ME = () => `${API_HOST}/auth/me`;
export const POST_FEEDBACK = () => `${API_HOST}/feedback/`;
export const POST_QA = () => `${API_HOST}/ayahs/`;
export const POST_QA_HADETHES = () => `${API_HOST}/hadethes/`;

import Kurdi2 from "assets/images/reciters/Kurdi2.jpg";
import Alhashim from "assets/images/reciters/Alhashim.jpeg";
import Alluhaydan from "assets/images/reciters/Alluhaydan.jpeg";
import AlMansary from "assets/images/reciters/AlMansary.jpeg";

export const reciters = [
  {
    key: "Alhashim",
    value: "Салах аль-Хашим",
    shortName: "аль-Хашим",
    url: Alhashim,
  },
  {
    key: "Luhaydan",
    value: "Мухаммад аль-Люхайдан",
    shortName: "аль-Люхайдан",
    url: Alluhaydan,
  },
  // {
  //   key: "Basir",
  //   value: "Басир",
  //   shortName: "Басир",
  // },
  {
    key: "Emad",
    value: "Имад аль-Мансари",
    shortName: "аль-Мансари",
    url: AlMansary,
  },
  // {
  //   key: "Kurdi",
  //   value: "Мухаммад аль-Курди",
  //   shortName: "аль-Курди",
  //   url: Kurdi,
  // },
  {
    key: "Kurdi2",
    value: "Мухаммад аль-Курди",
    shortName: "аль-Курди",
    url: Kurdi2,
  },
];

export const recitersShortName = {
  Alhashim: "аль-Хашим",
  Luhaydan: "аль-Люхайдан",
  Emad: "аль-Мансари",
  Kurdi: "аль-Курди",
  Kurdi2: "аль-Курди",
};
