import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      main_title: "Software development of any complexity",
      NAV_BAR: {
        services: "Services",
        stages: "Stages",
        features: "Features",
        contact: "Contact",
      },
      SERVICES: {
        saas: "SaaS (software as a service) - a form of cloud computing, a service model where subscribers are provided with a ready-made application software, fully serviced by the provider.",
        mobile:
          "Mobile app can be pre-installed on the device during production, downloaded by the user through various software distribution platforms. Also web applications that are handled on the client or server side.",
        web: "Web development is the process of creating a web site or web application. The main steps in the process are web design, page layout, client-side and server-side programming, and web server configuration.",
        seo: "Search engine optimization (SEO) - a set of measures for internal and external optimization to raise the position of the site in the results of search engines for certain user queries. It is needed in order to increase users traffic to your website and subsequent monetization of this traffic.",
        software:
          "Software development is the process of developing, refining, designing, programming, documenting, testing, and fixing bugs associated with building and maintaining applications, frameworks, or other software components.",
        design:
          "Design development - the choice of corporate identity and the overall concept of the site or mobile application, drawing design layouts.",
      },
      IDEAS: {
        FIRST: {
          title: "Our ideas",
          subtitle: "Turn your idea into a startup",
          content:
            "Our course is focused exclusively on projects that benefit people and nature. We are developers of humane technologies )",
          addition: "Number of technology startups",
        },
        SECOND: {
          title: "Our ideas",
          subtitle: "Software Optimization",
          content:
            "We are maximizing our efforts to optimize our software code base in order to reduce resource and power consumption, and consequently the amount of carbon emissions",
          addition: "Number of data centers for data processing",
        },
      },
      STAGES: {
        title: "Stages of software development",
        subtitle:
          "Following this steps will allow us to bring the final product to the network in a quality and timely manner",
        PICTURE1: {
          title: "Punctuality",
          description1:
            "Competent terms of reference will save us from long discussions of the details and provide clear criteria for the delivery of the project.",
          description2:
            "Uncertain expectations are longer than expectations whose end is in sight.",
        },
        ITEMS: {
          one: {
            title: "Drawing up the technical assignment",
            subtitle: "Formulation of the project concept.",
          },
          two: {
            title: "Interface design",
            subtitle: "Implementation of rules and limitations of automated operations.",
          },
          three: {
            title: "Development of service architecture and databases",
            subtitle: "High-load scalable system.",
          },
          four: {
            title: "Design development",
            subtitle: "Formation of layouts.",
          },
          five: {
            title: "Programming",
            subtitle: "Frontend and backend development.",
          },
          six: {
            title: "Implementing an interactive prototype",
            subtitle: "Testing.",
          },
          seven: {
            title: "Presentation of the finished version",
            subtitle: "Technical support.",
          },
        },
        PICTURE2: {
          title: "Reporting",
          description1:
            "The purpose of developing the terms of reference is to interpret the customer's thoughts, in order to understand the essence and content of the work done by us.",
          description2:
            "Waiting until the process starts takes longer than waiting in the middle of the process.",
        },
      },
      TEAM: {
        title: "We are a powerful and creative team",
        subtitle: "Our features",
        ITEMS: {
          one: {
            title: "Advanced technology",
          },
          two: {
            title: "Stunning designs",
          },
          three: {
            title: "Software stability",
          },
          four: {
            title: "24/7 Tech support",
          },
          five: {
            title: "Adherence to deadlines",
          },
          six: {
            title: "Creative team",
          },
        },
      },
      CONTACT: {
        title: "Contact",
        subtitle: "Our team will respond to you within 24 hours",
      },
      FOOTER: {
        title: "All rights reserved. Copyright",
        subtitle: "Any technology must be presumed guilty until proven innocent.",
        author: " David Brower.",
      },
      OTHER: {
        getInTouch: "Contact",
      },
    },
  },
  ru: {
    translation: {
      main_title: "нейропоиск по аятам с приминением передовых методов ИИ",
      NAV_BAR: {
        services: "Услуги",
        stages: "Этапы",
        features: "Особенности",
        contact: "Контакты",
      },
      SERVICES: {
        saas: "SaaS (англ. software as a service) — одна из форм облачных вычислений, модель обслуживания, при которой подписчикам предоставляется готовое прикладное программное обеспечение, полностью обслуживаемое провайдером.",
        mobile:
          "Разработка приложений для мобильных устройств могут быть предустановлены на устройство в процессе производства, загружены пользователем с помощью различных платформ для распространения ПО или являться веб-приложениями, которые обрабатываются на стороне клиента или сервера.",
        web: "Веб-разработка — процесс создания веб-сайта или веб-приложения. Основными этапами процесса являются веб-дизайн, вёрстка страниц, программирование на стороне клиента и сервера, а также конфигурирование веб-сервера.",
        seo: "Поисковая оптимизация (англ. search engine optimization, SEO) — комплекс мер по внутренней и внешней оптимизации для поднятия позиций сайта в результатах выдачи поисковых систем по определённым запросам пользователей, с целью увеличения сетевого трафика (для информационных ресурсов) и потенциальных клиентов (для коммерческих ресурсов) и последующей монетизации (получение дохода) этого трафика.",
        software:
          "Разработка программного обеспечения-это процесс разработки, уточнения, проектирования, программирования, документирования, тестирования и исправления ошибок, связанных с созданием и поддержкой приложений, фреймворков или других программных компонентов.",
        design:
          "Разработка дизайна – выбор фирменного стиля и общей концепции сайта или мобильного приложения, прорисовка дизайн-макетов.",
      },
      IDEAS: {
        FIRST: {
          title: "Наши мысли",
          subtitle: "Превратите свою идею в стартап.",
          content:
            "Наш курс сосредточен исключительно на проекты несущие в себе пользу людям и природе. Мы разработчики гуманных технологий )",
          addition: "Количество технологических стартапов",
        },
        SECOND: {
          title: "Наши мысли",
          subtitle: "Оптимизация ПО",
          content:
            "Мы максимально прикладываем усилия по оптимизации кодовой базы программного обеспечения, с целью уменьшения ресурсоемкости и энергопотребления, и, как следствие, количества углеродных выбросов )",
          addition: "Количество датацентров для обработки данных",
        },
      },
      STAGES: {
        title: "Этапы разработки ПО",
        subtitle:
          "Соблюдение этой последовательности позволит нам качественно и в сроки вывести конечный продукт в сеть",
        PICTURE1: {
          title: "Пунктуальность",
          description1:
            "Грамотное техническое задание спасет нас от долгих обсуждений деталей и обозначит четкие критерии сдачи проекта.",
          description2: "Неопределенные ожидания длиннее ожиданий, конец которых виден.",
        },
        ITEMS: {
          one: {
            title: "1. Составление технического задания",
            subtitle: "Формулирование концепции проекта.",
          },
          two: {
            title: "2. Проектирование интерфейса",
            subtitle: "Реализация правил и ограничений автоматизируемых операций.",
          },
          three: {
            title: "3. Разработка архитектуры сервиса и баз данных",
            subtitle: "Высоконагрузочная масштабируемая система.",
          },
          four: {
            title: "4. Разработка дизайна",
            subtitle: "Формирование макетов.",
          },
          five: {
            title: "5. Программирование",
            subtitle: "Frontend и backend разработка.",
          },
          six: {
            title: "6. Реализация интерактивного прототипа",
            subtitle: "Тестирование.",
          },
          seven: {
            title: "7. Презентация готовой версии",
            subtitle: "Техническая поддержка.",
          },
        },
        PICTURE2: {
          title: "Отчетность",
          description1:
            "Целью разработки ТЗ является интерпретация мыслей заказчика, для понимания сути и содержания работы, проделанной нами.",
          description2:
            "Ожидание до начала процесса тянется дольше, чем ожидание посреди процесса.",
        },
      },
      TEAM: {
        title: "Мы мощная и креативная команда",
        subtitle: "Наши особенности",
        ITEMS: {
          one: {
            title: "Передовые технологии",
          },
          two: {
            title: "Потрясающие дизайны",
          },
          three: {
            title: "Стабильность работы ПО",
          },
          four: {
            title: "24/7 Техподдержка",
          },
          five: {
            title: "Соблюдение дэдлайнов",
          },
          six: {
            title: "Креативная команда",
          },
        },
      },
      CONTACT: {
        title: "Контакты",
        subtitle: "Наша команда ответит вам в течение 24 часов",
      },
      FOOTER: {
        title: "Все права защищены. Авторское право",
        subtitle:
          "Любая технология должна считаться виновной до тех пор, пока не будет доказана ее невиновность.",
        author: " Дэвид Брауэр.",
      },
      OTHER: {
        getInTouch: "Связаться",
      },
    },
  },
};

const DETECTION_OPTIONS = {
  order: ["navigator"],
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  detection: DETECTION_OPTIONS,
  resources,
  fallbackLng: "en",
});

export default i18n;
