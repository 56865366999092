/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import appStore from "assets/app_zema.png";
import volunter from "assets/volunteer_activism.svg";
import React from "react";

function TodoList() {
  return (
    <Card sx={{ overflow: "hidden" }}>
      <MKBox p={3}>
        <Grid container wrap="nowrap" spacing={2} alignItems="center">
          <Grid item>
            <MKBox component="img" src={appStore} width="50px" height="50px" />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <MuiLink
              href="https://apple.co/3VzYnLm"
              target="_blank"
              rel="noreferrer"
              variant="button"
            >
              <MKTypography
                sx={{
                  fontSize: "0.85rem",
                  fontWeight: "bold",
                  textDecoration: "underline !important",
                  // lineHeight: "0px !important",
                }}
              >
                Скачать iOS приложение
              </MKTypography>
            </MuiLink>
          </Grid>
        </Grid>

        <Divider />

        <Grid container wrap="nowrap" spacing={2} alignItems="center">
          <Grid item>
            <MKBox component="img" src={appStore} width="50px" height="50px" />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <MuiLink
              href="https://play.google.com/store/apps/details?id=ai.zema.app&pli=1"
              target="_blank"
              rel="noreferrer"
              variant="button"
            >
              <MKTypography
                sx={{
                  fontSize: "0.85rem",
                  fontWeight: "bold",
                  textDecoration: "underline !important",
                  // lineHeight: "0px !important",
                }}
              >
                Скачать android приложение
              </MKTypography>
            </MuiLink>
          </Grid>
        </Grid>
      </MKBox>
    </Card>
  );
}

export default TodoList;
