/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/vr-bg.jpg";
import { useActionData, Form, useLocation, useLoaderData, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import MKSnackbar from "../../../../components/MKSnackbar";

function Cover() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  let actionData = useActionData();
  let loaderData = useLoaderData();

  const [rememberMe, setRememberMe] = useState(true);
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [errorMessage, setErrorMessage] = useState(null);
  const [typeOfSnackbar, setTypeOfSnackbar] = useState("dark");

  useEffect(() => {
    if (actionData && actionData.error) {
      setErrorMessage(actionData.error);
      setTypeOfSnackbar("error");
      setShow(true);
    }
    if (actionData && actionData.success) {
      setTypeOfSnackbar("success");
      setErrorMessage("Вам отправлено письмо");
      setShow(true);
    }
  }, [actionData, loaderData]);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Сбросить пароль
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Вы получите сообщение на почту
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <Form method="post" replace>
            <input type="hidden" name="redirectTo" value={from} />
            <MKBox mb={4}>
              <MKInput
                name="email"
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="example@gmail.com"
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                name="email2"
                type="email"
                label="Подтвердите email"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="example@gmail.com"
              />
            </MKBox>
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="dark" fullWidth type="submit">
                Сбросить
              </MKButton>
            </MKBox>
            <MKBox
              mt={3}
              mb={1}
              textAlign="center"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <MKTypography variant="button" color="text">
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12 }}
                >
                  Войти
                </MKTypography>
              </MKTypography>
              <MKTypography variant="button" color="text">
                <MKTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12 }}
                >
                  Создать аккаунт
                </MKTypography>
              </MKTypography>
            </MKBox>
          </Form>
        </MKBox>
      </Card>

      <MKBox component="section" py={6}>
        <Container>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color={typeOfSnackbar}
            icon="notifications"
            title={
              typeOfSnackbar === "error"
                ? "Ошибка 👀"
                : typeOfSnackbar === "success"
                ? "Успешно"
                : ""
            }
            content={errorMessage}
            dateTime=""
            open={show}
            close={() => setShow(false)}
          />
        </Container>
      </MKBox>
    </CoverLayout>
  );
}

export default Cover;
