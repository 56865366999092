import { POST_LOGIN, POST_RESET, POST_SIGNUP } from "config";

/**
 * This represents some generic auth provider API, like Firebase.
 */
export const fakeAuthProvider = {
  isAuthenticated: false,
  username: null,
  async signin(data) {
    const fetchUrl = POST_LOGIN();

    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
      const body = await response.json();
      if (response.status === 409 || response.status === 401) {
        throw new Error(body.detail);
      }

      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const result = await response.json();
    localStorage.setItem("access_token", result.access_token);

    await new Promise((r) => setTimeout(r, 100)); // fake delay
    fakeAuthProvider.isAuthenticated = true;
    // fakeAuthProvider.username = username;
  },
  async signup(data) {
    const fetchUrl = POST_SIGNUP();

    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
      const body = await response.json();
      console.log(body.detail);
      if (response.status === 409 || response.status === 400) {
        // setErrorMessage(body.error);
        throw new Error(body.detail);
      }

      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const result = await response.json();
    return result;
    // localStorage.setItem("access_token", result.access_token);
    // fakeAuthProvider.isAuthenticated = true;
    // fakeAuthProvider.username = username;
  },
  async reset(data) {
    const fetchUrl = POST_RESET();

    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
      const body = await response.json();
      console.log(body.detail);
      if (response.status === 409 || response.status === 400) {
        // setErrorMessage(body.error);
        throw new Error(body.detail);
      }

      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const result = await response.json();
    return result;
    // localStorage.setItem("access_token", result.access_token);
    // fakeAuthProvider.isAuthenticated = true;
    // fakeAuthProvider.username = username;
  },
  async signout() {
    await new Promise((r) => setTimeout(r, 100)); // fake delay
    fakeAuthProvider.isAuthenticated = false;
    fakeAuthProvider.username = "";
  },
};
