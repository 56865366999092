/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useCallback, useEffect, useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKInput from "components/MKInput";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";

import { POST_FEEDBACK } from "config";
import useAsync from "hooks/useAsync";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function NotificationModal(props) {
  const { show, setShow, setShowSnackber, toggleSnackbar, setErrorMessage, setTypeOfSnackbar } =
    props;
  const toggleModal = () => setShow(!show);

  const [content, setContent] = useState("");

  const fetchData = useCallback(async () => {
    const fetchUrl = POST_FEEDBACK();
    const accessToken = localStorage.getItem("access_token");

    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({
        content,
      }),
    });

    if (!response.ok) {
      const body = await response.json();
      if (response.status === 429) {
        setErrorMessage(body.error);
      } else if (response.status === 409 || response.status === 404) {
        setErrorMessage(body.detail);
      } else {
        setErrorMessage(error.message);
      }
      setTypeOfSnackbar("error");
      setShowSnackber(true);
    }

    const data = await response.json();

    return data;
  }, [content]);

  const { execute, status, value, error } = useAsync(fetchData, false);

  useEffect(() => {
    if (
      (error && error.message === "Failed to fetch") ||
      (error && error.message === "The string did not match the expected pattern.")
    ) {
      setErrorMessage("Упсссс... Что-то пошло не так (");
      setTypeOfSnackbar("error");
      setShow(true);
      setShowSnackber(true);
    }
    if (status === "success" && value) {
      setTypeOfSnackbar("success");
      setErrorMessage("Отзыв отправлен");
      setShow(false);
      setShowSnackber(true);
      setContent("");
    }
  }, [status, value, error]);

  const handleChange = (event) => {
    const value = event.target.value;
    setContent(value);
  };

  return (
    <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center", px: 3 }}>
      <Slide direction="down" in={show} timeout={100} appear={false}>
        <MKBox
          component="form"
          m={3}
          position="relative"
          maxWidth="500px"
          width="100%"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          variant="gradient"
          bgColor="info"
          shadow="sm"
          onSubmit={(event) => {
            event.preventDefault();
            if (content.trim().length) {
              execute();
            }
          }}
        >
          <MKBox display="flex" alginItems="center" justifyContent="space-between" py={3} px={2}>
            <MKTypography variant="h6" color="white">
              Как эффективнее искать
            </MKTypography>
            <CloseIcon
              color="white"
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={toggleModal}
            />
          </MKBox>
          <Divider light sx={{ my: 0 }} />
          <MKBox p={2}>
            <MKTypography
              pl={1}
              mb={2}
              sx={{ borderLeft: "2px solid black" }}
              // textTransform="uppercase"
              variant="button"
              color="white"
              component="div"
              gutterBottom
              fontWeight="bold"
            >
              Пробуйте перефразировать свой вопрос <br />
              или поисковую строку.
            </MKTypography>
            <MKTypography
              mb={2}
              pl={1}
              sx={{ borderLeft: "2px solid black" }}
              // textTransform="uppercase"
              variant="button"
              color="white"
              component="div"
              gutterBottom
              fontWeight="bold"
            >
              Старайтесь использовать ключевые слова <br />
              из смыслового текста Корана и хадисов.
            </MKTypography>
            <MKTypography
              pl={1}
              mb={2}
              sx={{ borderLeft: "2px solid black" }}
              // textTransform="uppercase"
              variant="button"
              color="white"
              component="div"
              gutterBottom
              fontWeight="bold"
            >
              Достаточно короткого вопроса или поисковой строчки, <br />
              например: &quot;Кто оставил пророка?&quot; или &quot;обтирание носков&quot;.
            </MKTypography>

            <MKTypography
              pl={1}
              sx={{ borderLeft: "2px solid black" }}
              // textTransform="uppercase"
              variant="button"
              color="warning"
              component="div"
              gutterBottom
              fontWeight="bold"
            >
              Дорогие пользователи, мы стремительно пополняем нашу базу данных, если результаты
              вашего поискового запроса не удовлетворили вас, то это может быть связано с тем, что
              данная информация еще не доступна на нашем сервисе, проверьте позже или попробуйте
              изменить ваш запрос.
            </MKTypography>
          </MKBox>
          <Divider light sx={{ my: 0 }} />
          <MKBox display="flex" justifyContent="space-between" py={2} px={1.5}>
            <MKButton variant="text" color="white" />
            <MKButton
              type="submit"
              color="white"
              disabled={status === "pending"}
              onClick={toggleModal}
            >
              OK
            </MKButton>
          </MKBox>
        </MKBox>
      </Slide>
    </Modal>
  );
}

export default NotificationModal;
