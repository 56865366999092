/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: zema.ai
 * Copyright zema.ai
 
 Coded by www.zema.ai
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// react-router-dom components
import { useLocation, Link } from "react-router-dom";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import React, { useCallback, useEffect, useRef, useState } from "react";
import { POST_CONFIRM } from "../../../config";
import useAsync from "hooks/useAsync";
import Container from "@mui/material/Container";
import MKSnackbar from "components/MKSnackbar";
import bgImage from "assets/images/vr-bg.jpg";

function ConfirmCover() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  const [typeOfSnackbar, setTypeOfSnackbar] = useState("dark");
  const [show, setShow] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const fetchData = useCallback(async () => {
    const pathname = location.pathname;
    const id = pathname.split("/").pop();

    const fetchUrl = POST_CONFIRM(id);
    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      const body = await response.json();
      if (response.status === 429) {
        setErrorMessage(body.error);
      } else if (response.status === 409 || response.status === 404) {
        setErrorMessage(body.detail);
      } else {
        setErrorMessage(error.message);
      }
      setTypeOfSnackbar("error");
      setShow(true);
    }

    const data = await response.json();

    return data;
  }, []);

  const { execute, status, value, error } = useAsync(fetchData, true);

  useEffect(() => {
    if (error && error.message === "Failed to fetch") {
      setErrorMessage("Ведутся технические работы");
      setTypeOfSnackbar("error");
      setShow(true);
    }
    if (status === "success" && value) {
      setTypeOfSnackbar("success");
      setErrorMessage(
        <div>
          Аккаунт активирован -{" "}
          <Link to="/login" style={{ textDecoration: "none" }}>
            авторизоваться
          </Link>
        </div>
      );
      setShow(true);
    }
  }, [status, value, error]);

  return (
    <>
      <CoverLayout image={bgImage}>
        <MKBox component="section" py={6}>
          <Container />
        </MKBox>
      </CoverLayout>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color={typeOfSnackbar}
        icon="notifications"
        title={
          typeOfSnackbar === "error" ? "Ошибка 👀" : typeOfSnackbar === "success" ? "Успешно" : ""
        }
        content={errorMessage}
        dateTime=""
        open={show}
        close={() => setShow(false)}
      />
    </>
  );
}

export default ConfirmCover;
