/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// react-router-dom components
import {
  Form,
  useActionData,
  useLocation,
  useNavigation,
  useLoaderData,
  Link,
} from "react-router-dom";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import MKSnackbar from "../../../../components/MKSnackbar";
import bgImage from "assets/images/vr-bg.jpg";
import iconGoogle from "assets/images/icons-google.svg";

function SignUpCover() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get("username") != null;

  let actionData = useActionData();
  let loaderData = useLoaderData();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [typeOfSnackbar, setTypeOfSnackbar] = useState("dark");

  useEffect(() => {
    if (actionData && actionData.error) {
      setErrorMessage(actionData.error);
      setTypeOfSnackbar("error");
      setShow(true);
    }
    if (actionData && actionData.success) {
      setTypeOfSnackbar("success");
      setErrorMessage("Вам отправлено письмо");
      setShow(true);
    }
  }, [actionData, loaderData]);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Создать аккаунт
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Введите свою почту для регистрации
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <Form method="post" replace>
            <MKBox>
              <input type="hidden" name="redirectTo" value={from} />
              <MKBox mb={2}>
                <MKInput
                  name="email"
                  variant="standard"
                  placeholder="example@gmail.com"
                  type="email"
                  label="Email"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  name="email2"
                  variant="standard"
                  placeholder="example@gmail.com"
                  type="email"
                  label="Подтвердите email"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </MKBox>
              {/*<MKBox mb={2}>*/}
              {/*  <MKInput*/}
              {/*    name="password"*/}
              {/*    variant="standard"*/}
              {/*    type="text"*/}
              {/*    label="Пароль"*/}
              {/*    placeholder="***********"*/}
              {/*    fullWidth*/}
              {/*    InputLabelProps={{ shrink: true }}*/}
              {/*  />*/}
              {/*</MKBox>*/}
              {/*<MKBox display="flex" alignItems="center" ml={-1}>*/}
              {/*  <Checkbox />*/}
              {/*  <MKTypography*/}
              {/*    variant="button"*/}
              {/*    fontWeight="regular"*/}
              {/*    color="text"*/}
              {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
              {/*  >*/}
              {/*    &nbsp;&nbsp;I agree the&nbsp;*/}
              {/*  </MKTypography>*/}
              {/*  <MKTypography*/}
              {/*    component="a"*/}
              {/*    href="#"*/}
              {/*    variant="button"*/}
              {/*    fontWeight="bold"*/}
              {/*    color="dark"*/}
              {/*    textGradient*/}
              {/*  >*/}
              {/*    Terms and Conditions*/}
              {/*  </MKTypography>*/}
              {/*</MKBox>*/}
              <MKBox mt={6} mb={1}>
                <MKButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  type="submit"
                  disabled={isLoggingIn}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1.5,
                    fontSize: 11,
                  }}
                >
                  {isLoggingIn ? "Создаю..." : "Создать аккаунт"}
                </MKButton>
              </MKBox>
            </MKBox>
            <MKBox
              mt={3}
              mb={1}
              textAlign="center"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <MKTypography variant="button" color="text">
                <MKTypography
                  component="a"
                  // href="http://localhost:8000/api/v1/google/login"
                  href="https://zema.ai/api/v1/google/login"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12, alignItems: "center", display: "flex", gap: 8 }}
                >
                  <img width={18} height={18} src={iconGoogle} alt="iconGoogle" />
                  Создать через Google
                </MKTypography>
              </MKTypography>
              <MKTypography variant="button" color="text">
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12 }}
                >
                  Войти
                </MKTypography>
              </MKTypography>
            </MKBox>
          </Form>
        </MKBox>
      </Card>

      <MKBox component="section" py={6}>
        <Container>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color={typeOfSnackbar}
            icon="notifications"
            title={
              typeOfSnackbar === "error" ? "Ошибка 👀" : typeOfSnackbar === "success" ? "Успех" : ""
            }
            content={errorMessage}
            dateTime=""
            open={show}
            close={() => setShow(false)}
          />
        </Container>
      </MKBox>
    </CoverLayout>
  );
}

export default SignUpCover;
