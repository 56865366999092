/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

// react-router-dom components
import { Form, useActionData, useLocation, useNavigation, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import Container from "@mui/material/Container";
import MKSnackbar from "components/MKSnackbar";
import bgImage from "assets/images/vr-bg.jpg";

function SignInCover() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get("username") != null;

  let actionData = useActionData();
  const [rememberMe, setRememberMe] = useState(true);
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [errorMessage, setErrorMessage] = useState(null);
  const [typeOfSnackbar, setTypeOfSnackbar] = useState("dark");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    if (actionData && actionData.error) {
      setErrorMessage(actionData.error);
      setTypeOfSnackbar("error");
      // toggleSnackbar();
      setShow(true);
    } else {
      setErrorMessage(null);
    }
  }, [actionData]);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Войти
          </MKTypography>
          {/*<p>You must log in to view the page at {from}</p>*/}
          <MKTypography display="block" variant="button" color="white" my={1}>
            Введите свой логин и пароль для входа
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <Form method="post" replace>
            <input type="hidden" name="redirectTo" value={from} />
            <MKBox mb={2}>
              <MKInput
                id="username"
                label="Логин"
                name="username"
                variant="standard"
                fullWidth
                placeholder="example"
                InputLabelProps={{ shrink: true }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="standard"
                fullWidth
                placeholder="***********"
                InputLabelProps={{ shrink: true }}
              />
            </MKBox>
            {/*<MKBox display="flex" alignItems="center" ml={-1}>*/}
            {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
            {/*  <MKTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    color="text"*/}
            {/*    onClick={handleSetRememberMe}*/}
            {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;Запомнить меня*/}
            {/*  </MKTypography>*/}
            {/*</MKBox>*/}
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="dark"
                fullWidth
                type="submit"
                disabled={isLoggingIn}
              >
                {isLoggingIn ? "Захожу" : "Войти"}
              </MKButton>
            </MKBox>
            <MKBox
              mt={3}
              mb={1}
              textAlign="center"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <MKTypography variant="button" color="text">
                <MKTypography
                  component={Link}
                  to="/reset"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12 }}
                >
                  Сбросить аккаунт
                </MKTypography>
              </MKTypography>
              <MKTypography variant="button" color="text">
                <MKTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                  style={{ fontSize: 12 }}
                >
                  Создать аккаунт
                </MKTypography>
              </MKTypography>
            </MKBox>
          </Form>
        </MKBox>
      </Card>

      <MKBox component="section" py={6}>
        <Container>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color={typeOfSnackbar}
            icon="notifications"
            title={
              typeOfSnackbar === "error"
                ? "Ошибка 👀"
                : typeOfSnackbar === "success"
                ? "Успешно"
                : ""
            }
            content={errorMessage}
            dateTime=""
            open={show}
            close={() => setShow(false)}
          />
        </Container>
      </MKBox>
    </CoverLayout>
  );
}

export default SignInCover;
