/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Emails() {
  const openMailClient = () => {
    const email = "info@zema.ai";
    const subject = "Предложить идею"; // можно изменить тему по умолчанию
    const body = "Ас Саляму алейкум,\n\n"; // можно изменить текст по умолчанию

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Card>
      <MKBox display="flex" justifyContent="center" p={3} lineHeight={1}>
        <Tooltip title="Отправить письмо" placement="top">
          <MKBox component="a" href="#" onClick={openMailClient}>
            <MKTypography variant="body2" fontWeight="regular">
              Предложить идею
            </MKTypography>
          </MKBox>
        </Tooltip>
      </MKBox>
    </Card>
  );
}

export default Emails;
