/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: zema.ai
* Copyright zema.ai

Coded by www.zema.ai

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react
import { useState, useRef, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import bgImage from "assets/images/vr-bg.jpg";

import { recitersShortName } from "../../../../../config";
import { blue } from "@mui/material/colors";
import * as React from "react";

function MediaPlayer(props) {
  const { reciter, setReciter, show, setShow, setTypeOfSnackbar, setErrorMessage } = props;
  const bgImage =
    "https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80";

  const mediaPlayerButtonStyles = ({ functions: { pxToRem } }) => ({
    width: pxToRem(46),
    height: pxToRem(46),
    minWidth: pxToRem(46),
    minHeight: pxToRem(46),
    mr: 1,
  });

  const [surahNumber, setSurahNumber] = useState("001");

  const [audioQuranUrl, setAudioQuranUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(
    new Audio("https://zemaai.s3.eu-central-1.amazonaws.com/audio/bySurahs/Alhashim/001.mp3")
  );
  useEffect(() => {
    setAudioQuranUrl(
      `https://zemaai.s3.eu-central-1.amazonaws.com/audio/bySurahs/${reciter}/${surahNumber}.mp3`
    );
  }, [surahNumber, reciter]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = new Audio(audioQuranUrl);
      setIsPlaying(false);
    }
  }, [audioQuranUrl]);

  const [loading, setLoading] = useState(null);
  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        setLoading("pending");
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            setLoading("loaded");
          })
          .catch((error) => {
            console.log(error.message, "error");
            if (
              error.message === "Failed to load because no supported source was found." ||
              error.message === "The element has no supported sources."
            ) {
              setLoading("loaded");
              setIsPlaying(false);
              setErrorMessage("Ведутся технические работы");
              setShow(true);
              setTypeOfSnackbar("error");
            }
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const handleChangeSurahNumber = (num) => {
    setLoading("loaded");
    let newSurahNumber = String(parseInt(surahNumber) + num);

    if (newSurahNumber.length === 1) {
      newSurahNumber = `00${newSurahNumber}`;
    } else if (newSurahNumber.length === 2) {
      newSurahNumber = `0${newSurahNumber}`;
    }

    if (parseInt(newSurahNumber) >= 1 && parseInt(newSurahNumber) <= 114) {
      setSurahNumber(newSurahNumber);
    }
  };

  return (
    <Card
      sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
        backgroundImage: `${linearGradient(
          rgba(gradients.dark.main, 0.85),
          rgba(gradients.dark.state, 0.85)
        )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      })}
    >
      <MKBox p={3} position="relative" lineHeight={0}>
        <MKTypography variant="h5" color="white" fontWeight="medium">
          {recitersShortName[reciter]}
        </MKTypography>
        <MKTypography variant="button" color="white" fontWeight="regular">
          Сура {parseInt(surahNumber, 10)}
        </MKTypography>
        <MKBox display="flex" mt={6} pt={1}>
          <MKBox display="flex" alignItems="center" justifyContent="center">
            <Tooltip title="Назад" placement="top">
              <MKButton
                variant="outlined"
                size="large"
                circular
                iconOnly
                sx={mediaPlayerButtonStyles}
                onClick={() => handleChangeSurahNumber(-1)}
              >
                <Icon>skip_previous</Icon>
              </MKButton>
            </Tooltip>
            <Tooltip title={isPlaying ? "Пауза" : "Играть"} placement="top">
              <Box sx={{ position: "relative", top: 0, right: 0 }}>
                <MKButton
                  variant="outlined"
                  size="large"
                  circular
                  iconOnly
                  sx={mediaPlayerButtonStyles}
                  onClick={togglePlay}
                >
                  <Icon>{isPlaying ? "pause_arrow" : "play_arrow"}</Icon>
                </MKButton>
                {loading === "pending" && (
                  <CircularProgress
                    size={46}
                    sx={{
                      color: blue[500],
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            </Tooltip>
            <Tooltip title="Вперед" placement="top">
              <MKButton
                onClick={() => handleChangeSurahNumber(1)}
                variant="outlined"
                size="large"
                circular
                iconOnly
                sx={mediaPlayerButtonStyles}
              >
                <Icon>skip_next</Icon>
              </MKButton>
            </Tooltip>
          </MKBox>
        </MKBox>
      </MKBox>
    </Card>
  );
}

export default MediaPlayer;
